<template>
    <div class="texts_upper">
        <b-container class="texts_container">
            <b-row class="texts_wrap" align-h="center" align-v="center">
                <b-col lg="12" md="9" sm="9" cols="9" class="text_wrap">
                    <h2 v-if="data.upTitle" :class="data.lineUp?'line-before':''">{{data.upTitle}}</h2>
                    <h3 v-if="data.title" :class="data.lBottom?'b-bottom':''">{{data.title}}</h3>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    props:{
        data: Object,
    }
}
</script>

<style scoped>
.texts_container{
    padding-top: 40px;
    padding-bottom: 40px;
}
.texts_container .texts_wrap h2{
    font-size: 30px;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: -0.12px;
    text-align: right;
    color: var(--texto);
}
.texts_container .texts_wrap .line-before:before{
    content: "";
    width: 60px;
    height: 2.6px;
    left: 0;
    top: 0;
    display: inline-block;
    background: var(--primario);
    margin-right: 8.4px;
}
.texts_container .texts_wrap h3{
    font-size: 27px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.3;
    letter-spacing: -0.11px;
    color: var(--oscuro);
    padding-bottom: 36px;
    text-align: center;
}
.texts_container .texts_wrap h3.b-bottom:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 3px;
    width: 70%;
    max-width: 560px;
    transform: translateX(-50%);
    background: var(--primario);
}
@media(max-width: 992px){
    .texts_container .texts_wrap h3{
        font-size: 20px;
        line-height: 2;
    }
    
    .texts_container .texts_wrap .line-before{
        text-align: left;
    }
}
@media(max-width: 576px){
    .texts_container .texts_wrap h3{
        font-size: 17px;
    }
    .texts_container .texts_wrap h3.b-bottom:after{
        width: 100%;
        max-width: 100%;
    }
}
</style>