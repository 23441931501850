<template>
    <div class="imageS_upper" :class="[data.fullRow?'full':'',data.position, data.inverted?'inverted':'']">
        <b-container class="imageS_container" :class="data.fullRow?'full':''">
            <b-row class="imageS_wrap" align-v="center" :class="data.inverted?'inverted':''">
                <b-col lg="6" md="12" sm="12" class="image_wrap">
                    <h3 v-if="data.upTitle" :class="data.lineUp?'line-before':''">{{data.upTitle}}</h3>
                    <h2 v-if="data.title" :class="data.lineTitle?'line-before':''">{{data.title}}</h2>
                    <p v-if="data.textLeft" v-html="data.textLeft"></p>
                </b-col>
                <b-col lg="6" md="12" sm="12" class="image_wrap">
                    <b-img :class="data.position" :src="require('@/assets/'+data.imgRight)" fluid alt="Responsive image"></b-img>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    props:{
        data: Object,
    }
}
</script>

<style scoped>
.imageS_upper{
    background: linear-gradient(90deg, var(--transparente) 53vw, var(--bg-light) 53vw);
}
.imageS_upper.full{
    background: linear-gradient(90deg, var(--transparente) 30vw, var(--bg-light) 30vw);
}
.imageS_container .imageS_wrap.inverted{
    flex-direction: row-reverse;
}
.imageS_container .imageS_wrap h3{
    font-size: 19.2px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.08px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    color: var(--texto);
}
.imageS_container .imageS_wrap .line-before:before{
    content: "";
    width: 60px;
    height: 2.6px;
    left: 0;
    top: 0;
    display: inline-block;
    background: var(--primario);
    margin-right: 8.4px;
}
.imageS_container .imageS_wrap h2{
    font-size: 30px;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: -0.12px;
    text-align: left;
    color: var(--texto);
}
.imageS_container.full .imageS_wrap p{
    font-size: 30px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.37;
    letter-spacing: 0.42px;
    text-align: center;
}
.imageS_container .imageS_wrap p{
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.94px;
    text-align: left;
    color: var(--texto);
}
.imageS_container .imageS_wrap img{
    padding: 52px 0;
}
.imageS_container .imageS_wrap img.up{
    padding-top: 0;
    margin-top: -40px;
}
.imageS_container.full .imageS_wrap img{
    padding: 15px 0;
}
@media(max-width: 992px){
    .imageS_upper{
        padding-top: 28px;
        background: transparent;
    }
    .imageS_container .imageS_wrap h2{
        font-size: 20px;
    }
    .imageS_container.full .imageS_wrap p{
        font-size: 25px;
        line-height: 1.1;
    }
    .imageS_container .imageS_wrap p{
        font-size: 15px;
    }
    .imageS_container .imageS_wrap img{
        width: 100%;
        height: 150px;
        padding-top: 10px;
        padding-bottom: 10px;
        object-fit: cover;
    }
    .imageS_container .imageS_wrap img.up{
        margin-top: 0;
        height: 450px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .imageS_upper.up{
        background-image: url('~@/assets/img/bg-v-rectangle.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 50% 75%;
    }
    .imageS_container.full{
        max-width: 100%;
    }
    .imageS_container.full .image_wrap:last-child{
        padding: 0;
    }
    .imageS_upper.up .imageS_container .line-before{
        text-align: center;
    }
    .imageS_upper.up .imageS_container .line-before:before{
        content: none;
    }
    .imageS_upper.up .imageS_container p{
        padding: 0 15%;
    }
    .imageS_container .imageS_wrap.inverted{
        flex-direction: column-reverse;
    }
    .imageS_upper.inverted{
        background: linear-gradient(180deg, var(--transparente) 53vw, var(--bg-light) 30vw);
    }
    .imageS_container .imageS_wrap.inverted img{
        height: 400px;
    }
}
@media(max-width: 576px){
    .imageS_container .imageS_wrap p{
        font-size: 12px;
        text-align: center;
        margin: 0 20px;
    }
    .imageS_upper.up .imageS_container p{
        padding: 0 8%;
    }
    .imageS_container.full .imageS_wrap p{
        font-size: 20px;
        padding-top: 15px;
        padding-bottom: 35px;
    }
    .imageS_container .imageS_wrap img{
        height: 68px;
    }
}
</style>